<template>
  <KTCard
    :page="page"
    :isForm="true"
    :isEdit="isEdit"
    @submit="onSubmit"
    :isLoading="ApiIsLoading"
    :cancelTo="parentRoute"
    :readonly="finalizado"
  >
    <template #body>
      <p class="mb-2">
        <span class="font-weight-bold">Produto:</span> {{ descricao_produto }}
      </p>
      <p class="mb-6">
        <span class="font-weight-bold">Unidade de Medida:</span>
        {{ nome_unidade_medida }}
      </p>

      <div class="accordion" role="tablist" v-if="form.length">
        <b-card
          no-body
          class="mb-1"
          v-for="(item, index) in form"
          :key="'ano-' + item.ano"
        >
          <b-card-header
            header-tag="header"
            class="p-3 d-flex justify-content-between align-items-center"
            role="tab"
            :style="{ cursor: 'auto' }"
          >
            <b v-if="finalizado">Dados do Ano {{ item.ano }}:</b>
            <b v-else>Informar dados do Ano {{ item.ano }}:</b>
            <b-button
              v-b-toggle="'accordion-' + item.ano"
              variant="secondary"
              size="sm"
            >
              <span v-if="!accordion[index]">
                <i class="fa fa-angle-down"></i> Abrir
              </span>
              <span v-else> <i class="fa fa-angle-up"></i> Fechar </span>
            </b-button>
          </b-card-header>
          <b-collapse
            :id="'accordion-' + item.ano"
            accordion="my-accordion"
            role="tabpanel"
            v-model="accordion[index]"
          >
            <b-card-body>
              <div class="d-flex justify-content-between mb-4">
                <p class="font-weight-bold font-size-lg" v-if="finalizado">
                  Quantidade prevista de vendas por mês:
                </p>
                <p class="font-weight-bold font-size-lg" v-else>
                  Informe abaixo a quantidade prevista de vendas por mês:
                </p>
                <b-button
                  variant="secondary"
                  size="sm"
                  v-if="index > 0 && !finalizado"
                  @click="repetirValores(index)"
                >
                  Repetir valores do ano anterior
                </b-button>
              </div>
              <b-row>
                <b-col
                  sm="6"
                  md="3"
                  lg="2"
                  v-for="mes in 12"
                  :key="'col-' + mes"
                >
                  <b-form-group label-size="lg" class="mb-4">
                    <template #label>
                      Mês {{ mes }} <span class="text-danger">*</span>
                    </template>
                    <template #default>
                      <KTInputNumeric
                        v-model="form[index]['mes_' + mes]"
                        :precision="2"
                        autocomplete="off"
                        :disabled="finalizado"
                      />
                    </template>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";
import MsgService from "@/core/services/msg.service.js";

export default {
  name: "PipPlanoVendasForm",
  props: {
    idplano_investimento: {
      required: true
    },
    idpip_produto: {
      default: null
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page_options: {
        icon: "la la-calendar",
        title: "Plano de Vendas",
        description_pp: `A projeção das receitas compreende tanto o
          montante destinado ao autoconsumo (receita não-monetária)
          como o que é comercializado ou vendido (receita monetária).
          Preencha os campos abaixo informando tudo que se espera
          produzir e que estará disponível para ser comercializado ou
          consumido. Caso ocorra algum período de entressafra e não
          tenha produção para comercializar preencha o campo com zero.`,
        description_pn: `Aqui é o momento de planejar as vendas de todos
          os produtos cadastrados. A projeção deve ser realizada para cada
          mês e para todos os anos para o qual a análise está sendo realizada.
          Preencha com “0” meses sem previsão de venda. Seja criterioso e
          realista!`
      },
      form: [],
      anos: 0,
      descricao_produto: "",
      nome_unidade_medida: "",
      accordion: [true],
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/plano_vendas`;
    },
    manual() {
      return {
        session: "plano_vendas",
        tipo: this.tipo_plano_investimento
      };
    },
    isEdit() {
      return !!this.idpip_produto;
    },
    parentRoute() {
      return {
        name: "pip_plano_vendas_list",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    page() {
      return {
        ...this.page_options,
        description: this.isPP
          ? this.page_options.description_pp
          : this.page_options.description_pn
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetData(this.idpip_produto)];

      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;

        this.anos = res[R_GETDATA].anos;
        this.descricao_produto = res[R_GETDATA].descricao_produto;
        this.nome_unidade_medida = res[R_GETDATA].nome_unidade_medida;
        if (res[R_GETDATA].plano_vendas) {
          this.form = res[R_GETDATA].plano_vendas;
        } else {
          this.form = [];
          for (let i = 1; i <= this.anos; i++) {
            this.form.push({
              idpip_produto: this.idpip_produto,
              ano: i
            });
          }
        }
        this.tipo_plano_investimento = res[R_GETDATA].tipo_plano_investimento;
        this.finalizado = res[R_GETDATA].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    onSubmit() {
      if (this.validaCampos()) {
        this.ApiSubmitUpdate(this.idpip_produto, this.form);
      }
    },
    repetirValores(indexAtual) {
      this.form[indexAtual].mes_1 = this.form[indexAtual - 1].mes_1;
      this.form[indexAtual].mes_2 = this.form[indexAtual - 1].mes_2;
      this.form[indexAtual].mes_3 = this.form[indexAtual - 1].mes_3;
      this.form[indexAtual].mes_4 = this.form[indexAtual - 1].mes_4;
      this.form[indexAtual].mes_5 = this.form[indexAtual - 1].mes_5;
      this.form[indexAtual].mes_6 = this.form[indexAtual - 1].mes_6;
      this.form[indexAtual].mes_7 = this.form[indexAtual - 1].mes_7;
      this.form[indexAtual].mes_8 = this.form[indexAtual - 1].mes_8;
      this.form[indexAtual].mes_9 = this.form[indexAtual - 1].mes_9;
      this.form[indexAtual].mes_10 = this.form[indexAtual - 1].mes_10;
      this.form[indexAtual].mes_11 = this.form[indexAtual - 1].mes_11;
      this.form[indexAtual].mes_12 = this.form[indexAtual - 1].mes_12;
    },
    validaCampos() {
      let isValid = true;
      let errorAno = "";
      let errorMes = "";

      for (const item of this.form) {
        for (var [key, value] of Object.entries(item)) {
          if (
            key.includes("mes_") &&
            !key.includes("_f") &&
            (value === null || typeof value == "undefined")
          ) {
            errorMes = key.replace("mes_", "");
            errorAno = item.ano;
            isValid = false;
            break;
          }
        }

        if (!isValid) {
          break;
        }
      }

      if (!isValid) {
        MsgService.show({
          title: "Erro no preenchimento de dados",
          description: `Informe o valor do mês ${errorMes} no ano ${errorAno}.`,
          type: "error"
        });
        return false;
      }
      return true;
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
